.App {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

h1 {
  margin: 0;
}

.app-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
}

.drop-area {
  border: 2px dashed #48c8ff;
  border-radius: 5px;
  background: #d0e9ff38;
  cursor: pointer;
}

.drop-area:hover {
  background: #a0e9ff38;
  transition: .3s all;
}

.drop-area input {
  /*display: none;*/
}

.drop-area__description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-content p {
  font-size: 24px;
  margin: 1em;
  text-align: center;
}

button {
  background: #48C8FF;
  color: #fff;
  padding: 20px 40px;
  font-size: 20px;
  border: 0;
  outline: 0;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background: #1daeed;
  transition: .2s all
}

.result {
  flex-direction: column;
  max-width: 1200px;
}

.result-image {
  width: 100%;
  max-width: 512px;
  margin-top: 2em;
}

.result-image img {
  width: 100%;
}